<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <AppHeader sticky v-bind="$attrs" />
      <div class="flex-grow min-w-0">
        <slot />
      </div>
      <AppFooter />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup></script>
